import React from "react";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";

function MainHeader() {
  return (
    <header className="fixed flex flex-wrap sm:justify-start sm:flex-nowrap z-[10000] w-full text-sm py-4 bg-black">
      <nav
        className="max-w-[85rem] w-full mx-auto px-4 flex items-center justify-between"
        aria-label="Global"
      >
        <NavLink
          className="font-medium text-gray-200 hover:text-gray-400 text-base"
          to="/about-us"
          aria-current="page"
        >
          About Us
        </NavLink>
        <Logo />
        <NavLink
          className="font-medium text-gray-200 hover:text-gray-400 text-base"
          to="/contact-us"
          aria-current="page"
        >
          Contact
        </NavLink>
      </nav>
    </header>
  );
}

export default MainHeader;
