import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./Root";

const ErrorPage = React.lazy(() => import("./error-page"));
const Main = React.lazy(() => import("./pages/Main"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Device = React.lazy(() => import("./pages/Device"));

const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense>
            <Main />
          </Suspense>
        ),
      },
      {
        path: "/about-us",
        element: (
          <Suspense>
            <About />
          </Suspense>
        ),
      },
      {
        path: "/contact-us",
        element: (
          <Suspense>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: "/grey-lantern",
        element: (
          <Suspense>
            <Device />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
