import React from "react";
import footerLists from "../footer";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="block bg-black w-full p-4">
      <div className="block sm:flex flex-wrap sm:flex-nowrap items-start justify-between gap-4 sm:p-5">
        <Logo />
        <div className="flex flex-row flex-wrap items-start justify-between min-w-[60%] gap-4 my-5 sm:my-0">
          {footerLists?.map((sections) => (
            <ul key={sections.title} className="space-y-3 text-sm">
              <p className="text-gray-100 font-bold">{sections.title}</p>
              {sections?.items.map((item) => (
                <li key={item.title} className="flex space-x-3">
                  {sections.external ? (
                    <a
                      className="text-gray-400 text-base"
                      target="_blank"
                      referrerPolicy="same-origin"
                      href={item.path}
                      aria-label={item.title}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <NavLink
                      className="text-gray-400 text-base"
                      to={item.path}
                      aria-label={item.title}
                    >
                      {item.title}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
