const footerLists = [
  {
    title: "PRODUCT",
    items: [
      {
        title: "Grey Lantern",
        path: "/grey-lantern",
      },
      {
        title: "Features",
        path: "/grey-lantern#features",
      },
      {
        title: "Device Options",
        path: "/grey-lantern#deviceOptions",
      },
      {
        title: "Partner",
        path: "/grey-lantern#partner",
      },
    ],
  },
  {
    title: "COMPANY",
    items: [
      {
        title: "About Us",
        path: "/about-us",
      },
      {
        title: "Contact",
        path: "/contact-us",
      },
    ],
  },
  {
    title: "SOCIALS",
    external: true,
    items: [
      {
        title: "Twitter",
        path: "/",
      },
      {
        title: "LinkedIn",
        path: "https://www.linkedin.com/company/cleanmeter/",
      },
      {
        title: "Facebook",
        path: "/",
      },
      {
        title: "Instagram",
        path: "/",
      },
    ],
  },
];

export default footerLists;
