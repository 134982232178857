import React from "react";
import { NavLink } from "react-router-dom";
import Image from "./Image";
import LOGO_PNG from "../assets/logo.png";
import LOGO_WEBP from "../assets/logo.webp";

function Logo() {
  return (
    <NavLink className="flex-none text-xl font-semibold " to="/">
      <Image
        src={LOGO_WEBP}
        fallback={LOGO_PNG}
        alt="cleanmeter-logo"
        className="object-contain object-center"
      />
    </NavLink>
  );
}

export default Logo;
