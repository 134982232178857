import { Outlet } from "react-router-dom";
import MainHeader from "./components/MainHeader";
import React, { Suspense } from "react";
import Footer from "./components/Footer";
import MobileHeader from "./components/MobileHeader";
import useMediaQuery from "./hooks/useMediaQuery";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const MainHeader = React.lazy(() => import("./components/MainHeader"));
// const MobileHeader = React.lazy(() => import("./components/MobileHeader"));

export default function Root() {
  const matches = useMediaQuery("(min-width: 768px)");
  return (
    <React.Fragment>
      {matches ? <MainHeader /> : <MobileHeader />}
      <main>
        <Outlet />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </main>
      <Footer />
    </React.Fragment>
  );
}
